import { v4 as uuidv4 } from 'uuid';

export default function scheduleData() {
    return [
        {
            id: uuidv4(),
            day: "MONDAY",
            classes: [
                {
                    id: uuidv4(),
                    start: "7:00 PM to",
                    end: "08:30 PM",
                    class: "Gi Class"
                },
            ]
        },
        {
            id: uuidv4(),
            day: "TUESDAY",
            classes: [
                {
                    id: uuidv4(),
                    start: "05:30 PM to",
                    end: "07:00 PM",
                    class: "Women's Only"
                },
                {
                    id: uuidv4(),
                    start: "7:00 PM to",
                    end: "08:30 PM",
                    class: "No-Gi Class"
                },
            ]
        },
        {
            id: uuidv4(),
            day: "WEDNESDAY",
            classes: [
                {
                    id: uuidv4(),
                    start: "7:00 PM to",
                    end: "08:30 PM",
                    class: "Competition Class"
                },
            ]
        },
        {
            id: uuidv4(),
            day: "THURSDAY",
            classes: [
                {
                    id: uuidv4(),
                    start: "7:00 PM to",
                    end: "08:30 PM",
                    class: "Fundamentals"
                },
            ]
        },
    ]
}
