// Packages
import React from 'react';
import { motion } from 'framer-motion';

// Components

// Data

// Animation
import { fadeIn } from '../../data/animationData';

export default function ScheduleDay({day}) {

    return (
        <motion.div className="schedule-day" initial="before" animate="after" variants={fadeIn}>
            <h2>{day.day}</h2>
            {day.classes.map(c =>
                <div key={c.id} className="schedule-class">
                    <p className="class-day">{c.class}</p>
                    <p className="class-time">{c.start} {c.end}</p>
                </div>
            )}
        </motion.div>
    );
}