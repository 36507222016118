// Packages
import React from 'react';

// Components
import ScheduleDay from './ScheduleDay';

// Data
import scheduleData from '../../data/scheduleData';

export default function ScheduleContent() {

    const data = scheduleData();

    return (
        <div className="schedule-content content-wrapper">
            {data.map(d =>
                <ScheduleDay key={d.id} day={d}></ScheduleDay>  
            )}
        </div>
    );
}

