// Packages
import React from 'react';

// Components
import Layout from '../components/Layout';
import PageHeader from '../components/reusables/PageHeader';
import ScheduleContent from '../components/schedule/ScheduleContent';
import FooterContact from '../components/reusables/FooterContact';
import HeadMetadata from '../components/HeadMetadata';

export default function Schedule() {
    return (
        <Layout>
            <HeadMetadata title="Schedule"></HeadMetadata>
            <PageHeader
                image="kal.jpg"
                title="SEE OUR SCHEDULE"
                text=""
            ></PageHeader>
            <ScheduleContent></ScheduleContent>
            <FooterContact
                bg_color="platinum"
                fg_color="rich-black-fogra"
            ></FooterContact>
        </Layout>
    );
}